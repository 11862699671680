/* For webkit-based browsers (Safari, Chrome) */
::-webkit-scrollbar {
  width: px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
}

/* For Firefox */
/* Note: Firefox supports scrollbar styling using scrollbar-width property (requires Firefox 64+) */
/* You can also use the ::-moz-scrollbar pseudo-element for older versions */
.scrollbar {
  scrollbar-width: auto; /* or 'auto' or 'none' */
  scrollbar-color: #888 #f1f1f1; /* Color of the thumb and track */
}






