@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root{
  --header-1: 32px;
  --header-2: 30px;
  --header-3: 18px;
  --header-4: 15px;
  --header-5: 14px;
  --header-6: 10px;
  --header: 24px;
  --caption: 12px;
	--font-family-main:'Poppins', sans-serif;
  --font-family-secondary:'Montserrat';
  --font-family-tertiary:'Work Sans';
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  --color-primer:#4AA8FF;
  --color-primary-variant: #329cff;
  --color-secondary:#319795;
  --color-header:#09322D;
  --color-secondary-variant:#41BF06;
  --color-background : #f6f6f6;
  --color-surface: #FCFFFC;
  --color-grey: #ABABAB;
  --color-hover: #efefef;
  --color-error: #B00020;
  --color-on-primary: #FFFFFF;
  --color-on-background: #000000;
  --color-on-error: #FFFFFF;
  --color-info: #007BFF;
  width: 100%;
}

#root{
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
